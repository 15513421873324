main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}

/* Styling */

.container {
  text-align: center;
  background: url('https://images.unsplash.com/photo-1487284122274-e864e9dec2bf?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60');
  background-size: cover;
  background-repeat: none;
  padding: 50px 20px;
  height: 60vh;
}

.dashboard-home {
  padding: 40px;
  margin: 0px 200px;
  background-color: #EAEFFB;
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  border-radius: 20px;
  border: 1px solid grey;
}